import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%",
    fontSize : "18px"
  }
}));

export default function PrivacyZhPage() {
  const classes = useStyles();

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .no-background {
        background-image: none !important;
      }
    `;
    document.head.appendChild(style);
    document.body.classList.add('no-background');
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <IntlProvider>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.panel}>

          <h2><b>隱私</b></h2>
            <pre>
{`
免責條款
本網頁所載全部資料僅供參考。World Care Sheung Wan Elderly Centre Limited Limited不保證或聲明本網頁所提供的資料屬準確、完整或最新，並表示既不會就因本網頁的內容而產生及因依賴本網頁內容而引致的任何損失而承擔任何責任。

個人資料收集聲明
在 World Care Sheung Wan Elderly Centre Limited Limited，我們尊重並保護您的隱私。我們收集與使用您的資訊，是為了向您提供安全而個性化的照護服務。以下，我們將說明我們可能會如何使用您的個人資料：

1. 為了提供您最適切的護理和醫療服務，我們可能需要收集您的健康和醫療歷史。

2. 我們可能使用您的聯絡資訊來定期向您的家人或護理人員報告您的健康狀況。

3. 為了改善我們的服務和設施，我們可能會使用您的反饋和意見。

4. 我們可能會使用您的資訊來處理您的付款和帳單。

我們可能會提供您的個人資訊給我們的合作夥伴，如醫療服務提供者或第三方支付機構，以便他們能夠協助我們提供您所需的服務。我們只會在法律允許的範圍內以及在您同意的情況下，分享您的個人資訊。

您的資訊隨時可以被您查閱和更正。如果您希望查閱或更正您的個人資訊，請聯絡我們。

存取及修正個人資料的權利
根據《個人資料（私隱）條例》，您有權要求查閱及修正我們持有您的個人資料。如果您希望查閱或更正您的個人資料，請以書面形式（包括電子郵件）提出要求。

改變本政策的權利
我們保留在任何時候修改本隱私政策的權利。如果我們作出任何修改，我們將在我們的網站上公布修改的政策。我們鼓勵您定期查閱本政策以確保您了解我們如何使用您的個人資料。

其他
如果您對 World Care Sheung Wan Elderly Centre Limited Limited的隱私政策有任何問題或疑慮，請聯絡我們。如您要提出查閱或更正個人資料的要求，或查詢有關我們隱私政策的問題，可以向我們的客戶服務主任提出：maxfairdeveloper@gmail.com。
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
